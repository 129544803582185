import {template} from './AddressSearch.template'
import {template as templateItem} from './AddressItem.template'

// AlertMessage.ts
export class AddressSearch extends HTMLElement {

    private partner: string | null = null;
    private domain: string = 'https://partner.solarsolution.no';
    private timeout: number;
    private search: HTMLInputElement;

    constructor() {
        super();
        this.attachShadow({mode: 'open'});
        this.shadowRoot.appendChild(template.content.cloneNode(true));
        this.search = this.shadowRoot.querySelector('input');
    }

    // noinspection JSUnusedGlobalSymbols
    connectedCallback(): void {

        // Clicking on wrapper should toggle focus on input.
        this.shadowRoot.getElementById('addressSearchWrap').addEventListener('click', this.setFocus);

        // We need to handle user input
        this.search.addEventListener('input', this.updateInput);

    }

    // noinspection JSUnusedGlobalSymbols
    disconnectedCallback(): void {
        this.shadowRoot.getElementById('addressSearchWrap').removeEventListener('click', this.setFocus);
        this.search.removeEventListener('input', this.updateInput);
    }

    // noinspection JSUnusedGlobalSymbols
    static get observedAttributes() {
        return ['partner', 'domain', 'no-credits', 'logo-variant'];
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string) {
        if (name === "partner" && newValue !== oldValue) {
            this.partner = newValue;
        }
        if (name === "domain" && newValue !== oldValue) {
            this.domain = newValue;
        }
        if (name === "no-credits" && newValue !== oldValue) {
            this.toggleCredits();
        }
        if (name === "logo-variant" && newValue !== oldValue) {
            this.setLogoVariant(newValue);
        }
    }

    private fetchData = async (address: string) => {

        let endpoint = this.domain + '/estimate/search/'

        if (this.partner) {
            endpoint += '?partner=' + this.partner;
        }

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                body: new URLSearchParams({addressSuggest: address})
            }).finally(() => {
                this.loadingState(false);
            });

            if (!response.ok) throw new Error('Network response was not ok');

            const result = await response.json();

            if (!result.success) throw new Error(result.message);

            this.updateResults(result.data);

        } catch (error) {
            if (error instanceof Error) {
                console.error(error);
                this.displayError(error.message);
            } else {
                console.error(error)
            }
        }

    }


    private updateResults = (data: { latitude: number, longitude: number, name: string }[]) => {

        const resultContainer = this.toggleResultContainer(true);

        if (data.length > 0) {
            data.forEach(item => resultContainer.appendChild(this.createItemElement(item)));
        } else {
            resultContainer.innerHTML = '<div id="noResult">Ingen resultat funnet for adressen.</div>';
        }

    }


    private displayError = (message: string) => {
        this.toggleResultContainer(true).innerHTML = `<div id="errorResult">${message}</div>`;
    }


    private toggleResultContainer = (show: boolean) => {
        const resultContainer = this.shadowRoot!.getElementById('addressResult')!;
        resultContainer.innerHTML = '';
        resultContainer.style.display = show ? 'grid' : 'none';
        return resultContainer;
    }


    // Show loading spinner to indicate that new result is loading
    private loadingState = (show: boolean) => {
        this.shadowRoot!.getElementById('addressSearchIcon')!.classList.toggle('loading', show)
    }

    // Set focus to address input
    private setFocus = () => {
        this.shadowRoot.querySelector('input').focus();
    }


    // Create result item
    private createItemElement = (item: { latitude: number, longitude: number, name: string }) => {
        const template = templateItem.content.cloneNode(true) as HTMLElement;
        template.querySelector('span[slot="address"]').textContent = item.name;
        let link = `${this.domain}/estimate/production?lat=${item.latitude}&lng=${item.longitude}`;
        if (this.partner) {
            link += `&partner=${this.partner}`;
        }
        template.querySelector('a').setAttribute('href', link);
        return template;
    }


    private toggleClearIcon = (show: boolean) => {
        const clear = this.shadowRoot!.getElementById('clearSearch')!;
        clear.style.display = show ? 'flex' : 'none';
        show ? clear.addEventListener('click', this.clearSearchAction) : clear.removeEventListener('click', this.clearSearchAction);
    }

    private clearSearchAction = () => {
        this.search.value = '';
        this.updateInput();
    }


    private updateInput = () => {

        // Show or hide clear search icon
        this.toggleClearIcon(this.search.value.length > 0);

        // Clear timeout
        clearTimeout(this.timeout);

        if (this.search.value.length > 2) {
            // Show loading and defer search input
            this.loadingState(true);
            this.timeout = setTimeout(() => this.fetchData(this.search.value), 500);
        } else {
            this.loadingState(false);
            this.toggleResultContainer(false);
        }

    }

    private toggleCredits = () => {
        this.shadowRoot.querySelector('#credits').classList.toggle('hide');
    }

    private setLogoVariant = (value: string) => {
        const logo = this.shadowRoot?.querySelector<HTMLElement>('#credits svg');
        if (!logo) return;

        const logoAccent = logo.querySelector<HTMLElement>('#link-logo-solar');
        const logoLeaf = logo.querySelector<HTMLElement>('#link-logo-leaf');

        const colors = {
            dark: {logo: '#000', accent: null, leaf: null},
            white: {logo: '#fff', accent: null, leaf: null},
            monoDark: {logo: '#000', accent: '#000', leaf: '#000'},
            monoWhite: {logo: '#fff', accent: '#fff', leaf: '#fff'},
            default: {logo: '#808080', accent: null, leaf: null}
        };

        const variant = colors[value as keyof typeof colors] || {
            logo: null,
            accent: null,
            leaf: null
        };
        logo.style.fill = variant.logo ?? null;
        logoAccent.style.fill = variant.accent ?? null;
        logoLeaf.style.fill = variant.leaf ?? null;
    }

}
